import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import HomePage from "./pages/home";
import CatalogPage from "./pages/catalog";
import { makeServer } from "./mock";
import PrivacyPage from "./pages/privacy";
import NotFoundPage from "./pages/not-found";

if (process.env.NODE_ENV === "development") {
  makeServer({ environment: "development" });
}

function App() {
  return (
    <Routes>
      <Route path="/" element={<HomePage />}>
        <Route path="/catalogo" element={<CatalogPage />} />
        <Route path="/" element={<Navigate to="/catalogo" replace />} />
      </Route>
      <Route path="/privacy" element={<PrivacyPage />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
}

export default App;
