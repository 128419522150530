import { IconBrandCakephp } from "@tabler/icons-react";
import React from "react";
import { useNavigate } from "react-router-dom";

const NotFoundPage = () => {
  const navigate = useNavigate();

  return (
    <div className="w-full h-screen bg-alabaster-100 flex justify-center items-center flex-col gap-5">
      <span className="text-2xl font-bold">
        ¡Ups! No encontramos la página que buscas.
      </span>
      <IconBrandCakephp size={30} color="black" />
      <button onClick={() => navigate("/")} className="btn shadow-md">
        Vuelve al inicio
      </button>
    </div>
  );
};

export default NotFoundPage;
