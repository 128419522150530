import {
  IconRosetteDiscountCheckFilled,
  IconRosetteDiscountCheckOff,
} from "@tabler/icons-react";
import React, { useContext, useEffect, useState } from "react";
import { ALERT_DISPLAY_ON_MS } from "../../contants";
import { BaseContext } from "../../context";
import { AlertType } from "../../context/alert";

const Alert = () => {
  const { alert } = useContext(BaseContext);

  const getAlertTypeClass = () => {
    let result = "";

    switch (alert.value.type) {
      case AlertType.SUCCESS:
        result = "alert-success";
        break;
      case AlertType.FAIL:
        result = "alert-fail";
        break;
    }

    return result;
  };

  const getAlertTypeIcon = () => {
    let result = null;

    switch (alert.value.type) {
      case AlertType.SUCCESS:
        result = <IconRosetteDiscountCheckFilled size={20} />;
        break;
      case AlertType.FAIL:
        result = <IconRosetteDiscountCheckOff size={20} />;
        break;
    }

    return result;
  };

  return alert.value.display ? (
    <div className="fixed w-full z-50 flex justify-center px-20 py-10">
      <div role="alert" className={`alert ${getAlertTypeClass()}`}>
        {getAlertTypeIcon()}
        <span>{alert.value.text}</span>
      </div>
    </div>
  ) : null;
};

export default Alert;
