import React from "react";

const Logo = ({ className }: { className?: string }) => {
  return (
    <div
      className={`${className}`}
      style={{
        letterSpacing: "-0.07em",
      }}
    >
      <img src="logo.png" alt="" />
    </div>
  );
};

export default Logo;
