import { useEffect, useState } from "react";
import { MODAL_ID } from "../../contants";

export type ModalData = {
  title: string;
  component: any;
};

export type ModalContextType = {
  value: ModalData;
  openModal: (title: string, component: any) => void;
  closeModal: () => void;
};

export const MODAL_CONTEXT_INIT = {
  value: {
    title: "",
    component: null,
  },
  openModal: (title: string, component: any) => {},
  closeModal: () => {},
};

export const GetModalContextMethods = () => {
  const [title, setTitle] = useState<string>("");
  const [component, setComponent] = useState<any>(null);

  const [data, setData] = useState<ModalData>({ title: "", component: null });

  const openModal = (title: string, component: any) => {
    setTitle((current: any) => {
      setComponent((current: any) => {
        (document.getElementById(MODAL_ID) as any)?.showModal();
        return component;
      });

      return title;
    });
  };

  const closeModal = () => {
    setTitle("");
    setComponent(null);
    (document.getElementById(MODAL_ID) as any)?.close();
  };

  useEffect(() => {
    setData({
      title,
      component,
    });
  }, [title, component]);

  return {
    data,
    openModal,
    closeModal,
  };
};
