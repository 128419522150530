const HEADERS = {};

const request = (url: string, method: string, body?: any, headers?: any) => {
  return new Promise<any>((resolve, reject) => {
    fetch(url, {
      method: method,
      ...(body ? { body: JSON.stringify(body) } : {}),
      ...(headers ? { ...headers, ...HEADERS } : { ...HEADERS }),
    })
      .then((response) => response.json())
      .then((data) => resolve(data));
  });
};

export const get = (url: string, headers?: any) => {
  return request(url, "GET", undefined, headers);
};

export const post = (url: string, body: any, headers?: any) => {
  return request(url, "POST", body, headers);
};

export const put = (url: string, body: any, headers?: any) => {
  return request(url, "PUT", body, headers);
};

export const del = (url: string, headers?: any) => {
  return request(url, "DELETE", undefined, headers);
};
