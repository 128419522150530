import React, { useContext, useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import Logo from "../../components/logo";
import {
  INSTAGRAM_LINK,
  LINKTREE_LINK,
  MENU_OPTIONS,
  WHATSAPP_LINK,
} from "../../contants";
import {
  IconBrandInstagram,
  IconBrandLinktree,
  IconBrandWhatsapp,
} from "@tabler/icons-react";
import { post } from "../../client/base";
import { BaseContext } from "../../context";
import { AlertType } from "../../context/alert";

const HomePage = () => {
  const { alert } = useContext(BaseContext);
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedOption, setSelectedOption] = useState<string>("");

  const [subscribeEmail, setSubscribeEmail] = useState<string>("");
  const [subscribing, setSubscribing] = useState<boolean>(false);
  const [subscribeBtnDisabled, setSubscribeBtnDisabled] =
    useState<boolean>(false);

  const getFirstLocation = () => {
    return location.pathname.split("/")[1];
  };

  const onSelected = (key: string) => {
    return key.toLowerCase() == selectedOption;
  };

  const selectOption = (key: string) => {
    navigate(key.toLowerCase());
    setSelectedOption(key.toLowerCase());
  };

  useEffect(() => {
    setSelectedOption(getFirstLocation().toLowerCase());
  }, [location]);

  const isValidSubscriptionEmail = () => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(subscribeEmail);
  };

  const sendEmailSubscribtion = () => {
    if (isValidSubscriptionEmail()) {
      setSubscribing(true);
      post(`https://api.goodcakes.com.co/subscribe`, {
        email: subscribeEmail,
      })
        .then((data) => {
          if (data.content) {
            setSubscribeEmail("");
            alert.turnOn(
              "¡Gracias por tu mensaje! Recibimos tu correo y te mantendremos al tanto de nuestras últimas novedades y productos.",
              AlertType.SUCCESS,
            );
          }
        })
        .catch((error) => {
          alert.turnOn(
            "¡Oops! Algo salió mal al intentar suscribirte. Por favor, vuelve a intentarlo.",
            AlertType.FAIL,
          );
        })
        .finally(() => {
          setSubscribing(false);
        });
    }
  };

  useEffect(() => {
    setSubscribeBtnDisabled(!isValidSubscriptionEmail());
  }, [subscribeEmail]);

  return (
    <div className="h-full w-full flex flex-col justify-between overflow-x-hidden no-scrollbar">
      <header className="bg-alabaster-100">
        <div className="w-full h-64 flex justify-center items-center">
          <div className="flex flex-col items-center gap-7">
            <div className="h-24 flex items-center">
              <Logo className="w-60" />
            </div>
            <div className="h-10 flex flex-row items-center gap-5">
              {MENU_OPTIONS.map((option) => (
                <div className="flex flex-col">
                  <span
                    className="font-league font-lighter text-xl cursor-pointer"
                    onClick={() => selectOption(option.path)}
                  >
                    {option.name.toLowerCase()}
                  </span>
                  {
                    <div
                      className={`w-full bg-gray-700 transition-opacity 
                                                ${onSelected(option.path) ? "opacity-100 h-0.5" : "opacity-0 h-0"}`}
                    ></div>
                  }
                </div>
              ))}
            </div>
          </div>
        </div>
      </header>
      <div className="bg-alabaster-100">
        <Outlet />
      </div>
      <footer className="flex flex-col justify-center items-center items-center py-10 gap-10">
        <div className="w-4/5 flex flex-col justify-end items-end gap-5">
          <div className="flex flex-row justify-around gap-4">
            <a href={LINKTREE_LINK} target="_blank">
              <IconBrandLinktree size={30} />
            </a>
            <a href={INSTAGRAM_LINK} target="_blank">
              <IconBrandInstagram size={30} />
            </a>
            <a
              href={WHATSAPP_LINK}
              target="_blank"
              className="flex flex-row justify-center items-center gap-1"
            >
              <IconBrandWhatsapp size={30} />
            </a>
          </div>
          <div className="w-full bg-black h-px opacity-30"></div>
        </div>
        <div className="flex flex-row h-40 w-4/5 justify-between phone:flex-col phone:justify-center phone:items-center phone:gap-10">
          <div className="w-80 flex flex-col gap-5 justify-center">
            <span className="font-league font-lighter text-lg">
              ¡Déjanos tu email y te mantendremos al tanto de nuestros nuevos
              productos!
            </span>
            <div className="join">
              <input
                className="input input-bordered join-item bg-white"
                placeholder="Correo Electrónico"
                onChange={(event) => setSubscribeEmail(event.target.value)}
                value={subscribeEmail}
              />
              <button
                className="btn join-item border-2 bg-white text-black"
                onClick={() => sendEmailSubscribtion()}
                disabled={subscribeBtnDisabled}
              >
                {subscribing ? (
                  <span className="loading loading-dots loading-xs"></span>
                ) : (
                  "Suscribirse"
                )}
              </button>
            </div>
          </div>
          <div className="w-48 flex justify-center items-end font-league">
            ©2024 goodCakes
          </div>
        </div>
        <div className="bg-white w-full flex flex-row justify-center items-center gap-1 text-xs font-light">
          <span>hecho por</span>
          <a href="https://vitessestack.com" target="_blank">
            Vitessestack
          </a>
        </div>
      </footer>
    </div>
  );
};

export default HomePage;
