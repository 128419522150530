import React, { useContext } from "react";
import { BaseContext } from "../../context";
import { MODAL_ID } from "../../contants";

const Modal = () => {
  const { modal } = useContext(BaseContext);

  return (
    <dialog id={MODAL_ID} className="modal">
      <div className="modal-box">
        <h1 className="font-bold text-4xl">{modal.value.title}</h1>
        <div className="divider"></div>
        {modal.value.component}
      </div>
      <form method="dialog" className="modal-backdrop">
        <button onClick={modal.closeModal}></button>
      </form>
    </dialog>
  );
};

export default Modal;
