import { IconShoppingCartFilled } from "@tabler/icons-react";
import React, { useContext, useEffect, useState } from "react";
import { BaseContext } from "../../context";
import CartItems from "./items";

const Cart = () => {
  const { cart, modal } = useContext(BaseContext);

  const [addedItem, setAddedItem] = useState<boolean>(false);
  const [notificationID, setNotificationID] = useState<NodeJS.Timeout | null>(
    null,
  );

  const openCartModal = () => {
    modal.openModal("Carrito", <CartItems />);
  };

  const turnItemAddedNotification = () => {
    if (notificationID == null) {
      setAddedItem(true);
      const timeoutID = setTimeout(() => {
        setNotificationID(null);
        setAddedItem(false);
      }, 3000);
      setNotificationID(timeoutID);
    }
  };

  useEffect(() => {
    if (cart.value.length > 0) {
      turnItemAddedNotification();
    }
  }, [cart.value]);

  return (
    <div className="h-52 fixed z-10 bottom-0 right-0 pr-10 pl-5 overflow-hidden flex flex-row justify-between items-center gap-5">
      {addedItem ? (
        <div className="rounded w-44 flex-1 px-5 py-3 bg-wheat-100 animate-growAppearDisappear">
          <span className="text-xs font-bold text-center flex justify-center items-center text-blackolive-100">
            Tu carrito ha sido actualizado
          </span>
        </div>
      ) : null}
      <button
        className="btn w-28 h-28 border-0 rounded-full bg-white hover:bg-alabaster-100 shadow-2xl flex justify-center items-center"
        onClick={openCartModal}
      >
        <div className="indicator">
          {cart.value.length > 0 && (
            <span className="indicator-item badge bg-wheat-100 text-blackolive-100">
              {cart.value.length}
            </span>
          )}
          <IconShoppingCartFilled size={40} color="#424340" />
        </div>
      </button>
    </div>
  );
};

export default Cart;
