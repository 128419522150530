import React, { useContext, useEffect, useState } from "react";
import { BaseContext } from "../../../context";
import { Product, ProductDenomination } from "../../../data/product";
import { post } from "../../../client/base";
import { CartProduct } from "../../../context/cart";
import { IconShoppingCart } from "@tabler/icons-react";
import CartInformation from "../information";
import { shortenString } from "../../../utils";

export type CartItem = {
  product?: Product;
  denomination?: ProductDenomination;
  quantity?: number;
};

const CartItems = () => {
  const { cart, modal } = useContext(BaseContext);

  const [items, setItems] = useState<CartItem[]>([]);
  const [loadingRequest, setLoadingRequest] = useState<boolean>(false);
  const [canOrder, setCanOrder] = useState<boolean>(false);

  const reset = () => {
    setItems([]);
    setCanOrder(false);
  };

  const getKey = (product: string, denomination: string) => {
    return `${product}&${denomination}`;
  };

  const mapItems = (products: Product[]) => {
    const itemsMap: Map<string, CartItem> = new Map();

    products.forEach((product) => {
      product.denominations.forEach((denomination) => {
        itemsMap.set(getKey(product.id, denomination.id), {
          product,
          denomination,
        });
      });
    });

    cart.value.forEach((cartProduct) => {
      const key = getKey(cartProduct.product_id, cartProduct.denomination_id);
      itemsMap.set(key, {
        ...(itemsMap.get(key) || {}),
        quantity: cartProduct.quantity,
      });
    });

    setItems(
      Array.from(itemsMap.values()).filter(
        (item) => item.product && item.denomination && item.quantity,
      ),
    );
  };

  const getProductsByIds = () => {
    setLoadingRequest(true);
    post("https://api.goodcakes.com.co/catalog/cart", {
      products: cart.value.map((item) => ({
        product: item.product_id,
        denomination: item.denomination_id,
      })),
    })
      .then((data: any) => {
        if (data.content) {
          mapItems(data.content);
        }
      })
      .finally(() => {
        setLoadingRequest(false);
      });
  };

  const getItemAmount = (item: CartItem) => {
    return (item.denomination?.price.value || 0) * (item.quantity || 0);
  };

  const getTotalAmount = () => {
    let amount = 0;

    items.forEach((item) => {
      amount += (item.denomination?.price?.value || 0) * (item.quantity || 0);
    });

    return amount;
  };

  const createOrder = () => {
    setLoadingRequest(true);
    post("https://api.goodcakes.com.co/order", {
      products: items,
    })
      .then((data: any) => {
        if (data.content) {
          if (data.content.successful) {
            reset();
            modal.closeModal();
            modal.openModal(
              "Procesando Pedido",
              <CartInformation orderID={data.content.order_id} />,
            );
          }
        }
      })
      .finally(() => {
        setLoadingRequest(false);
      });
  };

  const shortDescription = (description: string) => {
    return shortenString(description, 20);
  };

  useEffect(() => {
    getProductsByIds();
    setCanOrder(cart.value.length > 0);
  }, [cart.value]);

  return (
    <div className="flex justify-center items-center">
      {loadingRequest ? (
        <span className="loading loading-spinner loading-lg"></span>
      ) : (
        <div className="w-full h-full px-5 pb-5 flex flex-col gap-5">
          <div className="flex flex-col gap-2 h-96 overflow-y-scroll overflow-x-hidden px-2">
            {items.map((item) => (
              <div className="w-full min-h-24 max-h-24 overflow-hidden bg-alabaster-100 rounded flex flex-row gap-4 px-5 phone:flex-col justify-center items-center phone:min-h-48 phone:gap-1 phone:px-0">
                <div className="flex justify-center items-center">
                  <span className="text-xs font-thin text-nowrap">
                    x {item.quantity}
                  </span>
                </div>
                <div className="w-28 h-20 z-8 flex justify-center items-center">
                  <div className="h-20 w-20 flex justify-center items-center rounded-full overflow-hidden">
                    <img
                      className=""
                      src={item.product?.images[0] || ""}
                      alt={`${item.product?.id}_${item.denomination?.id}_image`}
                    />
                  </div>
                </div>
                <div className="flex flex-col justify-center items-start w-32 overflow-hidden phone:w-full phone:items-center z-9">
                  <div
                    className="text-xs font-bold flex flex-row gap-2 text-wrap"
                    data-tip={`${item.product?.name} - ${item.denomination?.weight}`}
                  >
                    <span>
                      {item.product?.name} {item.denomination?.weight}
                    </span>
                  </div>
                  <div
                    className="text-start"
                    data-tip={item.product?.description}
                  >
                    <span className="text-xs">
                      {shortDescription(item.product?.description as string)}
                    </span>
                  </div>
                </div>
                <div className="flex flex-row justify-center items-center gap-1">
                  <span className="text-sm font-bold">
                    {getItemAmount(item).toString()}
                  </span>
                  <span className="text-sm font-thin">
                    {item.denomination?.price.currency}
                  </span>
                </div>
              </div>
            ))}
          </div>
          <div className="divider m-0"></div>
          <div className="w-full flex flex-row justify-between px-2">
            <span className="text-sm font-bold">A Pagar</span>
            <div className="flex flex-row gap-2 justify-center items-center">
              <span className="text-lg font-bold">{getTotalAmount()}</span>
              <span className="text-sm font-thin">COP</span>
            </div>
          </div>
          <button
            className="btn flex flex-row gap-5"
            onClick={createOrder}
            disabled={!canOrder}
          >
            <span>Realizar Pedido</span>
            <IconShoppingCart size={15} />
          </button>
        </div>
      )}
    </div>
  );
};

export default CartItems;
