import React from "react";
import { BaseContext } from "./context";
import App from "./App";
import { GetCartContextMethods } from "./context/cart";
import Cart from "./components/cart";
import Modal from "./components/modal";
import { GetModalContextMethods } from "./context/modal";
import Alert from "./components/alert";
import { GetAlertContextMethods } from "./context/alert";

const ConfigurationWrapper = () => {
  const cartContextMethods = GetCartContextMethods();
  const modalContextMethods = GetModalContextMethods();
  const alertContextMethods = GetAlertContextMethods();

  return (
    <BaseContext.Provider
      value={{
        cart: {
          value: cartContextMethods.cartProducts,
          addProduct: cartContextMethods.addProduct,
          removeProduct: cartContextMethods.removeProduct,
          clean: cartContextMethods.clean,
          addActionToClean: cartContextMethods.addActionToClean,
        },
        modal: {
          value: modalContextMethods.data,
          openModal: modalContextMethods.openModal,
          closeModal: modalContextMethods.closeModal,
        },
        alert: {
          value: alertContextMethods.value,
          turnOn: alertContextMethods.turnOn,
        },
      }}
    >
      <Cart />
      <Alert />
      <App />
      <Modal />
    </BaseContext.Provider>
  );
};

export default ConfigurationWrapper;
