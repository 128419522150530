export const MENU_OPTIONS = [
  {
    name: "catálogo",
    path: "catalogo",
  },
];

export const WHATSAPP_LINK = "https://wa.me/+573053397661";
export const INSTAGRAM_LINK = "https://www.instagram.com/goodcakesrs";
export const LINKTREE_LINK = "https://linktr.ee/goodcakesrs";

export const CART_PRODUCT_QUANTITY_LIMIT = 10;

export const MODAL_ID = "modal_1";

export const ALERT_DISPLAY_ON_MS = 5000;
