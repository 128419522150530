import React, { useContext, useEffect, useState } from "react";
import { post } from "../../../client/base";
import { IconDeviceMobile } from "@tabler/icons-react";
import { BaseContext } from "../../../context";
import { AlertType } from "../../../context/alert";

export type ClientInformation = {
  phone: string;
};

const CartInformation = ({ orderID }: { orderID: string }) => {
  const { modal, cart, alert } = useContext(BaseContext);

  const [phone, setPhone] = useState<string>("");
  const [loadingRequest, setLoadingRequest] = useState<boolean>(false);
  const [canSend, setCanSend] = useState<boolean>(false);

  const reset = () => {
    setPhone("");
  };

  const getClientInformation = (): ClientInformation => {
    return {
      phone,
    };
  };

  const fieldsValid = () => {
    const phoneRegex = /^\+?[1-9]\d{1,14}$/;
    return phone.length > 0 && phoneRegex.test(phone);
  };

  const sendInformation = () => {
    if (fieldsValid()) {
      setLoadingRequest(true);
      post("https://api.goodcakes.com.co/order/information", {
        order_id: orderID,
        information: getClientInformation(),
      })
        .then((data: any) => {
          if (data.content) {
            if (data.content.successful) {
              reset();
              modal.closeModal();
              cart.clean();
              alert.turnOn(
                "¡Tu pedido está confirmado! Nos pondremos en contacto contigo muy pronto.",
                AlertType.SUCCESS,
              );
            } else {
              alert.turnOn(
                "Error al procesar el pedido. Por favor, inténtalo de nuevo.",
                AlertType.FAIL,
              );
            }
          }
        })
        .catch((error) => {
          alert.turnOn(
            "Error al procesar el pedido. Por favor, inténtalo de nuevo mas tarde.",
            AlertType.FAIL,
          );
        })
        .finally(() => {
          setLoadingRequest(false);
        });
    }
  };

  useEffect(() => {
    setCanSend(fieldsValid());
  }, [phone]);

  return (
    <div className="flex justify-center items-center w-full">
      {loadingRequest ? (
        <span className="loading loading-spinner loading-lg"></span>
      ) : (
        <div className="w-full p-5 flex flex-col gap-10">
          <div className="flex flex-row justify-center items-center gap-5">
            <span className="font-bold">
              ¡Introduce tu información de contacto y nos comunicaremos contigo
              para finalizar tu pedido!
            </span>
          </div>
          <label className="form-control w-full">
            <div className="label">
              <div className="label-text flex flex-row gap-2 justify-center items-center">
                <span>Número de teléfono</span>
                <IconDeviceMobile size={20} color="#424340" />
              </div>
            </div>
            <input
              type="text"
              placeholder="Tu teléfono aquí"
              className="input input-bordered w-full"
              onChange={(event) => setPhone(event.target.value)}
              value={phone}
            />
          </label>
          <button
            className="btn w-full"
            disabled={!canSend}
            onClick={sendInformation}
          >
            Enviar
          </button>
        </div>
      )}
    </div>
  );
};

export default CartInformation;
