import { useEffect, useState } from "react";
import { ALERT_DISPLAY_ON_MS } from "../../contants";

export enum AlertType {
  SUCCESS = "SUCCESS",
  FAIL = "FAIL",
}

export type AlertData = {
  display: boolean;
  text: string;
  type: AlertType;
};

export type AlertContextType = {
  value: AlertData;
  turnOn: (newText: string, newType: AlertType) => void;
};

export const EMPTY_ALERT_DATA = {
  display: false,
  text: "",
  type: AlertType.SUCCESS,
};

export const ALERT_CONTEXT_INIT = {
  value: EMPTY_ALERT_DATA,
  turnOn: (newText: string, newType: AlertType) => {},
};

export const GetAlertContextMethods = () => {
  const [display, setDisplay] = useState<boolean>(false);
  const [text, setText] = useState<string>("");
  const [type, setType] = useState<AlertType>(AlertType.SUCCESS);
  const [value, setValue] = useState<AlertData>(EMPTY_ALERT_DATA);

  const turnOn = (newText: string, newType: AlertType) => {
    setText((currrent: any) => {
      setType((current: any) => {
        setDisplay(true);
        return newType;
      });
      return newText;
    });
  };

  useEffect(() => {
    if (display) {
      setTimeout(() => {
        setDisplay(false);
      }, ALERT_DISPLAY_ON_MS);
    }
  }, [display]);

  useEffect(() => {
    setValue({
      display,
      text,
      type,
    });
  }, [display, text, type]);

  return {
    value,
    turnOn,
  };
};
