import { createContext } from "react";
import { CART_CONTEXT_INIT, CartContextType } from "./cart";
import { MODAL_CONTEXT_INIT, ModalContextType } from "./modal";
import { ALERT_CONTEXT_INIT, AlertContextType } from "./alert";

export type BaseContextType = {
  cart: CartContextType;
  modal: ModalContextType;
  alert: AlertContextType;
};

export const BaseContext = createContext<BaseContextType>({
  cart: CART_CONTEXT_INIT,
  modal: MODAL_CONTEXT_INIT,
  alert: ALERT_CONTEXT_INIT,
});
