import React, { useContext, useEffect, useState } from "react";
import Carousel from "../../../components/carousel";
import { Product, ProductDenomination } from "../../../data/product";
import { CART_PRODUCT_QUANTITY_LIMIT } from "../../../contants";
import { BaseContext } from "../../../context";

const CatalogProduct = ({ product }: { product: Product }) => {
  const { cart } = useContext(BaseContext);

  const [mappedDenominations, setMappedDenominatinos] = useState<
    Map<string, ProductDenomination>
  >(new Map());
  const [selectedDenomination, setSelectedDenomination] =
    useState<ProductDenomination | null>(null);
  const [quantity, setQuantity] = useState<number>(1);

  const resetSelection = () => {
    setQuantity(1);
    setSelectedDenomination(null);
  };

  const selectDenomination = (id: string) => {
    setSelectedDenomination(
      mappedDenominations.get(id) || product.denominations[0],
    );
  };

  const addToCart = () => {
    if (selectedDenomination != null) {
      cart.addProduct(product.id, selectedDenomination.id, quantity);
    }
  };

  useEffect(() => {
    setMappedDenominatinos((_) => {
      const newCurrent = new Map();
      product.denominations.forEach((denomination) => {
        newCurrent.set(denomination.id, denomination);
      });
      return newCurrent;
    });
  }, [product]);

  useEffect(() => {
    cart.addActionToClean(resetSelection);
  }, []);

  return (
    <div className="card bg-base-100 w-96 shadow-xl" key={product.id}>
      <figure className="overflow-hidden h-72">
        <Carousel id={product.id} images={product.images} />
      </figure>
      <div className="card-body gap-4">
        <h2 className="card-title flex-col gap-3">
          {product.name}
          <div className="flex flex-col gap-4 w-full justify-center items-center">
            <select
              className="select select-bordered w-full"
              onChange={(event) => selectDenomination(event.target.value)}
            >
              <option disabled selected={selectedDenomination == null}>
                Selecciona el tamaño
              </option>
              {product.denominations.map((denomination) => (
                <option
                  value={denomination.id}
                  selected={
                    selectedDenomination != null &&
                    denomination.id == selectedDenomination.id
                  }
                >
                  {denomination.weight}
                </option>
              ))}
            </select>
            {selectedDenomination && (
              <div className="flex flex-row gap-2">
                <div className="badge badge-secondary flex flex-row gap-1 bg-wheat-100 border-wheat-100 text-white">
                  <span>Precio:</span>
                  <span>{selectedDenomination.price.value}</span>
                  <span>{selectedDenomination.price.currency}</span>
                </div>
                <div className="badge badge-secondary flex flex-row gap-1 bg-khaki-100 border-khaki-100 text-white">
                  <span>{selectedDenomination.weight}</span>
                </div>
              </div>
            )}
          </div>
        </h2>
        <p>{product.description}</p>
        <div className="card-actions justify-end">
          {product.display_ingredients.map((ingredient) => (
            <div
              className="badge badge-outline"
              key={`${product.id}_${ingredient}`}
            >
              {ingredient}
            </div>
          ))}
        </div>
      </div>
      <div className="px-5 pb-5 w-full flex flex-row justify-between gap-5 items-end">
        <label className="form-control">
          <div className="label">
            <span className="label-text">Cantidad</span>
          </div>
          <select
            className="select select-bordered"
            onChange={(event) => setQuantity(parseInt(event.target.value))}
          >
            <option selected={quantity == 1}>1</option>
            {Array(CART_PRODUCT_QUANTITY_LIMIT + 1)
              .fill(null)
              .map(
                (_, index) =>
                  index > 1 && (
                    <option value={index} selected={quantity == index}>
                      {index}
                    </option>
                  ),
              )}
          </select>
        </label>
        <button
          className="btn flex-1"
          disabled={selectedDenomination == null}
          onClick={addToCart}
        >
          Añadir al Carrito
        </button>
      </div>
    </div>
  );
};

export default CatalogProduct;
