export const CATALOG_MOCK = [
  {
    id: "prod1",
    name: "torta de banano",
    denominations: [
      {
        id: "den_1",
        weight: "60gr",
        price: {
          value: 6500,
          currency: "COP",
        },
      },
      {
        id: "den_2",
        weight: "480gr",
        price: {
          value: 42000,
          currency: "COP",
        },
      },
    ],
    description:
      "sin azucar, sin gluten adfgafdgv afdbgdfb hgfad hadfgt hbdt hbadf b adf bfadbdfabadfb dfbfda bafd bdfab dfabd",
    display_ingredients: ["harina de almendra", "aceite de coco", "banano"],
    images: [
      "https://img.daisyui.com/images/stock/photo-1606107557195-0e29a4b5b4aa.webp",
      "https://img.daisyui.com/images/stock/photo-1625726411847-8cbb60cc71e6.webp",
      "https://img.daisyui.com/images/stock/photo-1606107557195-0e29a4b5b4aa.webp",
    ],
    categories: [
      {
        id: "cat_1",
        name: "Para el Desayuno",
        description:
          "Perfecta para empezar tus mañanas o cuando vayas a romper el ayuno",
      },
      {
        id: "cat_2",
        name: "Para Eventos",
        description: "La mejor opcion para tus cumpleaños",
      },
    ],
  },
  {
    id: "prod2",
    name: "torta de limon",
    denominations: [
      {
        id: "den_1",
        weight: "60gr",
        price: {
          value: 6500,
          currency: "COP",
        },
      },
      {
        id: "den_2",
        weight: "480gr",
        price: {
          value: 42000,
          currency: "COP",
        },
      },
    ],
    description: "sin azucar, sin gluten",
    display_ingredients: [
      "harina de almendra",
      "aceite de coco",
      "limon",
      "nuez",
      "huevo",
      "canela",
      "harina de coco",
    ],
    images: [
      "https://img.daisyui.com/images/stock/photo-1606107557195-0e29a4b5b4aa.webp",
      "https://img.daisyui.com/images/stock/photo-1625726411847-8cbb60cc71e6.webp",
      "https://img.daisyui.com/images/stock/photo-1606107557195-0e29a4b5b4aa.webp",
    ],
    categories: [
      {
        id: "cat_1",
        name: "Para el Desayuno",
        description:
          "Perfecta para empezar tus mañanas o cuando vayas a romper el ayuno",
      },
    ],
  },
  {
    id: "prod3",
    name: "torta de limon",
    denominations: [
      {
        id: "den_1",
        weight: "60gr",
        price: {
          value: 6500,
          currency: "COP",
        },
      },
      {
        id: "den_2",
        weight: "480gr",
        price: {
          value: 42000,
          currency: "COP",
        },
      },
    ],
    description: "sin azucar, sin gluten",
    display_ingredients: [
      "harina de almendra",
      "aceite de coco",
      "limon",
      "nuez",
      "huevo",
      "canela",
      "harina de coco",
    ],
    images: [
      "https://img.daisyui.com/images/stock/photo-1606107557195-0e29a4b5b4aa.webp",
      "https://img.daisyui.com/images/stock/photo-1625726411847-8cbb60cc71e6.webp",
      "https://img.daisyui.com/images/stock/photo-1606107557195-0e29a4b5b4aa.webp",
    ],
    categories: [
      {
        id: "cat_3",
        name: "Gusto sin Culpa",
        description: "Para despues de hacer ejercicio",
      },
      {
        id: "cat_4",
        name: "Reposteria Gourmet",
        description: "Para despues de hacer ejercicio",
      },
      {
        id: "cat_5",
        name: "Postres Super Saludables",
        description: "Para despues de hacer ejercicio",
      },
      {
        id: "cat_6",
        name: "Cuchareables",
        description: "Para despues de hacer ejercicio",
      },
      {
        id: "cat_7",
        name: "Como si fueran malos",
        description: "Para despues de hacer ejercicio",
      },
    ],
  },
  {
    id: "prod4",
    name: "torta de limon",
    denominations: [
      {
        id: "den_1",
        weight: "60gr",
        price: {
          value: 6500,
          currency: "COP",
        },
      },
      {
        id: "den_2",
        weight: "480gr",
        price: {
          value: 42000,
          currency: "COP",
        },
      },
    ],
    description: "sin azucar, sin gluten",
    display_ingredients: [
      "harina de almendra",
      "aceite de coco",
      "limon",
      "nuez",
      "huevo",
      "canela",
      "harina de coco",
    ],
    images: [
      "https://img.daisyui.com/images/stock/photo-1606107557195-0e29a4b5b4aa.webp",
      "https://img.daisyui.com/images/stock/photo-1625726411847-8cbb60cc71e6.webp",
      "https://img.daisyui.com/images/stock/photo-1606107557195-0e29a4b5b4aa.webp",
    ],
  },
  {
    id: "prod5",
    name: "torta de limon",
    denominations: [
      {
        id: "den_1",
        weight: "60gr",
        price: {
          value: 6500,
          currency: "COP",
        },
      },
      {
        id: "den_2",
        weight: "480gr",
        price: {
          value: 42000,
          currency: "COP",
        },
      },
    ],
    description: "sin azucar, sin gluten",
    display_ingredients: [
      "harina de almendra",
      "aceite de coco",
      "limon",
      "nuez",
      "huevo",
      "canela",
      "harina de coco",
    ],
    images: [
      "https://img.daisyui.com/images/stock/photo-1606107557195-0e29a4b5b4aa.webp",
      "https://img.daisyui.com/images/stock/photo-1625726411847-8cbb60cc71e6.webp",
      "https://img.daisyui.com/images/stock/photo-1606107557195-0e29a4b5b4aa.webp",
    ],
  },
  {
    id: "prod6",
    name: "torta de limon",
    denominations: [
      {
        id: "den_1",
        weight: "60gr",
        price: {
          value: 6500,
          currency: "COP",
        },
      },
      {
        id: "den_2",
        weight: "480gr",
        price: {
          value: 42000,
          currency: "COP",
        },
      },
    ],
    description: "sin azucar, sin gluten",
    display_ingredients: [
      "harina de almendra",
      "aceite de coco",
      "limon",
      "nuez",
      "huevo",
      "canela",
      "harina de coco",
    ],
    images: [
      "https://img.daisyui.com/images/stock/photo-1606107557195-0e29a4b5b4aa.webp",
      "https://img.daisyui.com/images/stock/photo-1625726411847-8cbb60cc71e6.webp",
      "https://img.daisyui.com/images/stock/photo-1606107557195-0e29a4b5b4aa.webp",
    ],
  },
  {
    id: "prod7",
    name: "torta de limon",
    denominations: [
      {
        id: "den_1",
        weight: "60gr",
        price: {
          value: 6500,
          currency: "COP",
        },
      },
      {
        id: "den_2",
        weight: "480gr",
        price: {
          value: 42000,
          currency: "COP",
        },
      },
    ],
    description: "sin azucar, sin gluten",
    display_ingredients: [
      "harina de almendra",
      "aceite de coco",
      "limon",
      "nuez",
      "huevo",
      "canela",
      "harina de coco",
    ],
    images: [
      "https://img.daisyui.com/images/stock/photo-1606107557195-0e29a4b5b4aa.webp",
      "https://img.daisyui.com/images/stock/photo-1625726411847-8cbb60cc71e6.webp",
      "https://img.daisyui.com/images/stock/photo-1606107557195-0e29a4b5b4aa.webp",
    ],
  },
  {
    id: "prod8",
    name: "torta de limon",
    denominations: [
      {
        id: "den_1",
        weight: "60gr",
        price: {
          value: 6500,
          currency: "COP",
        },
      },
      {
        id: "den_2",
        weight: "480gr",
        price: {
          value: 42000,
          currency: "COP",
        },
      },
    ],
    description: "sin azucar, sin gluten",
    display_ingredients: [
      "harina de almendra",
      "aceite de coco",
      "limon",
      "nuez",
      "huevo",
      "canela",
      "harina de coco",
    ],
    images: [
      "https://img.daisyui.com/images/stock/photo-1606107557195-0e29a4b5b4aa.webp",
      "https://img.daisyui.com/images/stock/photo-1625726411847-8cbb60cc71e6.webp",
      "https://img.daisyui.com/images/stock/photo-1606107557195-0e29a4b5b4aa.webp",
    ],
  },
  {
    id: "prod9",
    name: "torta de limon",
    denominations: [
      {
        id: "den_1",
        weight: "60gr",
        price: {
          value: 6500,
          currency: "COP",
        },
      },
      {
        id: "den_2",
        weight: "480gr",
        price: {
          value: 42000,
          currency: "COP",
        },
      },
    ],
    description: "sin azucar, sin gluten",
    display_ingredients: [
      "harina de almendra",
      "aceite de coco",
      "limon",
      "nuez",
      "huevo",
      "canela",
      "harina de coco",
    ],
    images: [
      "https://img.daisyui.com/images/stock/photo-1606107557195-0e29a4b5b4aa.webp",
      "https://img.daisyui.com/images/stock/photo-1625726411847-8cbb60cc71e6.webp",
      "https://img.daisyui.com/images/stock/photo-1606107557195-0e29a4b5b4aa.webp",
    ],
  },
  {
    id: "prod10",
    name: "torta de limon",
    denominations: [
      {
        id: "den_1",
        weight: "60gr",
        price: {
          value: 6500,
          currency: "COP",
        },
      },
      {
        id: "den_2",
        weight: "480gr",
        price: {
          value: 42000,
          currency: "COP",
        },
      },
    ],
    description: "sin azucar, sin gluten",
    display_ingredients: [
      "harina de almendra",
      "aceite de coco",
      "limon",
      "nuez",
      "huevo",
      "canela",
      "harina de coco",
    ],
    images: [
      "https://img.daisyui.com/images/stock/photo-1606107557195-0e29a4b5b4aa.webp",
      "https://img.daisyui.com/images/stock/photo-1625726411847-8cbb60cc71e6.webp",
      "https://img.daisyui.com/images/stock/photo-1606107557195-0e29a4b5b4aa.webp",
    ],
  },
  {
    id: "prod11",
    name: "torta de limon",
    denominations: [
      {
        id: "den_1",
        weight: "60gr",
        price: {
          value: 6500,
          currency: "COP",
        },
      },
      {
        id: "den_2",
        weight: "480gr",
        price: {
          value: 42000,
          currency: "COP",
        },
      },
    ],
    description: "sin azucar, sin gluten",
    display_ingredients: [
      "harina de almendra",
      "aceite de coco",
      "limon",
      "nuez",
      "huevo",
      "canela",
      "harina de coco",
    ],
    images: [
      "https://img.daisyui.com/images/stock/photo-1606107557195-0e29a4b5b4aa.webp",
      "https://img.daisyui.com/images/stock/photo-1625726411847-8cbb60cc71e6.webp",
      "https://img.daisyui.com/images/stock/photo-1606107557195-0e29a4b5b4aa.webp",
    ],
  },
];
