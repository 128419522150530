import React, { useEffect, useState } from "react";
import { Product, ProductCategory } from "../../data/product";
import { get } from "../../client/base";
import CatalogProduct from "./product";
import CatalogProductCategory from "./category";

const CatalogPage = () => {
  const [products, setProducts] = useState<Map<string, Product>>(new Map());
  const [gettingData, setGettingData] = useState<boolean>(false);

  const [categories, setCategories] = useState<Map<string, ProductCategory>>(
    new Map(),
  );
  const [categoriesProductsMap, setCategoriesProductsMap] = useState<
    Map<string, Set<string>>
  >(new Map());

  const [selectedCategories, setSelectedCategories] = useState<Set<string>>(
    new Set(),
  );
  const [selectedProducts, setSelectedProducts] = useState<Product[]>([]);

  const getProducts = () => {
    setGettingData(true);
    get("https://api.goodcakes.com.co/catalog")
      .then((data: { content: Product[] }) => {
        if (data.content) {
          setProducts((current) => {
            const newCurrent = new Map();

            data.content.forEach((prod) => {
              newCurrent.set(prod.id, prod);
            });

            return newCurrent;
          });
        }
      })
      .finally(() => {
        setGettingData(false);
      });
  };

  const setCategoriesData = () => {
    setCategories((current) => {
      const newCategories = new Map();
      const newCategoriesProduct = new Map();

      products.forEach((product) => {
        product.categories?.forEach((category) => {
          if (!newCategories.has(category.id)) {
            newCategories.set(category.id, category);
          }

          if (!newCategoriesProduct.has(category.id)) {
            newCategoriesProduct.set(category.id, new Set());
          }

          (newCategoriesProduct.get(category.id) as Set<string>).add(
            product.id,
          );
        });
      });

      setCategoriesProductsMap(newCategoriesProduct);
      setSelectedProducts(Array.from(products.values()));

      return newCategories;
    });
  };

  const updateSelectedProducts = () => {
    setSelectedProducts((current) => {
      let newCurrent: Product[] = [];

      if (selectedCategories.size > 0) {
        const catSelectedProductIds: Set<string> = new Set(
          Array.from(selectedCategories.values()).flatMap((catId) =>
            Array.from(categoriesProductsMap.get(catId) || new Set()),
          ),
        );
        newCurrent = Array.from(
          Array.from(catSelectedProductIds).map(
            (prodId) => products.get(prodId) as Product,
          ),
        );
      } else {
        newCurrent = Array.from(products.values());
      }

      return newCurrent;
    });
  };

  const onToggleCategory = (categoryId: string, value: boolean) => {
    if (value) {
      setSelectedCategories((current) => {
        const newCurrent = new Set(current);
        newCurrent.add(categoryId);
        return newCurrent;
      });
    } else {
      setSelectedCategories((current) => {
        const newCurrent = new Set(current);
        newCurrent.delete(categoryId);
        return newCurrent;
      });
    }
  };

  useEffect(() => {
    getProducts();
  }, []);

  useEffect(() => {
    if (products.size > 0) {
      setCategoriesData();
    }
  }, [products]);

  useEffect(() => {
    updateSelectedProducts();
  }, [selectedCategories]);

  return (
    <div className="flex justify-center items-center flex-col gap-20">
      {gettingData ? (
        <span className="loading loading-dots loading-lg"></span>
      ) : (
        <>
          <div className="w-[70%] flex justify-center flex-wrap gap-y-5 gap-x-10">
            {Array.from(categories.values()).map((category) => (
              <CatalogProductCategory
                category={category}
                onToggleCategory={onToggleCategory}
              />
            ))}
          </div>
          <div className="flex flex-row justify-around flex-wrap gap-10 pb-10">
            {selectedProducts.map((product) => (
              <CatalogProduct product={product} />
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default CatalogPage;
