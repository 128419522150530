import React, { useState } from "react";

const Carousel = ({ id, images }: { id: string; images: string[] }) => {
  const [selectedIndex, setSelectedIndex] = useState<number>(0);

  const prevIndex = () => {
    setSelectedIndex((selectedIndex == 0 ? images.length : selectedIndex) - 1);
  };

  const nextIndex = () => {
    setSelectedIndex((selectedIndex + 1) % images.length);
  };

  const isTherePrev = () => {
    return selectedIndex > 0;
  };

  const isThereNext = () => {
    return selectedIndex + 1 < images.length;
  };

  return (
    <div className="w-full h-80 bg-black flex justify-center items-center overflow-hidden">
      <img src={images.at(selectedIndex) || ""} className="h-80 object-cover" />
      <div className="flex flex-row justify-between absolute w-full px-5">
        <button
          onClick={prevIndex}
          disabled={!isTherePrev()}
          className="btn btn-circle"
        >
          ❮
        </button>
        <button
          onClick={nextIndex}
          disabled={!isThereNext()}
          className="btn btn-circle"
        >
          ❯
        </button>
      </div>
    </div>
  );
};

export default Carousel;
