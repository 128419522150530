import React, { useEffect, useState } from "react";
import { ProductCategory } from "../../../data/product";

const CatalogProductCategory = ({
  category,
  onToggleCategory,
}: {
  category: ProductCategory;
  onToggleCategory: (categoryId: string, value: boolean) => void;
}) => {
  const [isChecked, setIsChecked] = useState<boolean>(false);

  useEffect(() => {
    onToggleCategory(category.id, isChecked);
  }, [isChecked]);

  return (
    <button
      className={`form-control btn flex justify-center items-center px-5 py-2 tooltip rounded shadow-xl hover:bg-blackolive-100 hover:text-white cursor-pointer border-0 ${
        isChecked
          ? "bg-blackolive-100 text-white"
          : "bg-wheat-100 text-blackolive-100"
      }`}
      data-tip={category.description}
      onClick={() => setIsChecked((current) => !current)}
    >
      <span className={`font-bold flex justify-center items-center`}>{category.name}</span>
    </button>
  );
};

export default CatalogProductCategory;
