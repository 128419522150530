import { useEffect, useState } from "react";

export type CartProduct = {
  product_id: string;
  denomination_id: string;
  quantity: number;
};

export type CartContextType = {
  value: CartProduct[];
  addProduct: (
    product_id: string,
    denomination_id: string,
    quantity: number,
  ) => void;
  removeProduct: (product_id: string, denomination_id: string) => void;
  clean: () => void;
  addActionToClean: (action: () => void) => void;
};

export const CART_CONTEXT_INIT = {
  value: [],
  addProduct: (
    product_id: string,
    denomination_id: string,
    quantity: number,
  ) => {},
  removeProduct: (id: string) => {},
  clean: () => {},
  addActionToClean: (action: () => void) => {},
};

export const GetCartContextMethods = () => {
  const [products, setProducts] = useState<Map<string, CartProduct>>(new Map());
  const [cartProducts, setCartProducts] = useState<CartProduct[]>([]);
  const [actionsOnClean, setActionsOnClean] = useState<(() => void)[]>([]);

  const fromIdsToKey = (product_id: string, denomination_id: string) => {
    return `${product_id}&${denomination_id}`;
  };

  const addProduct = (
    product_id: string,
    denomination_id: string,
    quantity: number,
  ) => {
    setProducts((current) => {
      const newCurrent = new Map(current);
      newCurrent.set(fromIdsToKey(product_id, denomination_id), {
        product_id,
        denomination_id,
        quantity,
      });
      return newCurrent;
    });
  };

  const removeProduct = (product_id: string, denomination_id: string) => {
    setProducts((current) => {
      const newCurrent = new Map(current);
      newCurrent.delete(fromIdsToKey(product_id, denomination_id));
      return newCurrent;
    });
  };

  const addActionToClean = (action: () => void) => {
    setActionsOnClean((current) => [...current, action]);
  };

  const clean = () => {
    setProducts(new Map());
    actionsOnClean.forEach((action) => action());
    setActionsOnClean([]);
  };

  useEffect(() => {
    setCartProducts(Array.from(products.values()));
  }, [products]);

  return {
    addProduct,
    removeProduct,
    cartProducts,
    clean,
    addActionToClean,
  };
};
